import { lazy } from 'react'

import { safeSessionStorage } from './storage'

const sessionKey = 'lazy-retry'

type ComponentImportType<P> = () => Promise<{ default: React.ComponentType<P> }>

export const lazyWithRetry = <P,>(componentImport: ComponentImportType<P>, name: string) => {
  const key = `${sessionKey}-${name}`

  return lazy(async () => {
    const hasRefreshed = safeSessionStorage.getItem(key) === 'true' ?? false

    try {
      safeSessionStorage.setItem(key, 'false')

      return await componentImport()
    } catch (err) {
      if (err.toString().indexOf('ChunkLoadError') > -1) {
        if (!hasRefreshed) {
          safeSessionStorage.setItem(key, 'true')
          window.location.reload()
        }

        if (hasRefreshed) throw new Error(`Failed to load chunk [CLE]: ${err}`)
      } else throw err
    }

    return componentImport()
  })
}
