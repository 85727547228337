import styled from 'styled-components'
import './Loader.styles.scss'

const Layout = styled.div`
  display: flex;
  flex: 1;
  justify-items: center;
  align-items: stretch;
  height: 100vh;
  max-width: 100vw;
  overflow-x: hidden;
  justify-content: center;
  background: linear-gradient(to bottom, #f4f5f7 27%, #fcfcfd -27%);

  @media (min-width: 600px) {
    align-items: center;
  }
`

const LoaderWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  width: 100%;
  min-width: 100%;
  height: calc(100vh - 48px);
  max-height: 875px;
  background-color: #fff;
  overflow-x: hidden;
  overflow-y: auto;

  @media (min-width: 600px) {
    min-width: 448px;
    max-width: 448px;
    width: auto;
    align-items: center;
    box-shadow: 0px 40px 72px rgba(7, 12, 24, 0.08);
    border-radius: 8px;
  }
`

const LoaderContentWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;
  width: 100%;
  min-width: 100%;

  @media (min-width: 600px) {
    min-width: 448px;
  }

  padding-top: 40px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 28px;

  @media (min-width: 600px) {
    padding-left: calc(24px + 40 * ((100vw - 320px) / 1600));
    padding-right: calc(24px + 40 * ((100vw - 320px) / 1600));
  }

  @media (min-width: 1440px) {
    padding-left: 64px;
    padding-right: 64px;
  }
`

const LoaderLogoBox = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-top: 32px;
  margin-bottom: 32px;

  @media (min-width: 600px) {
    margin-top: '36px';
  }
`

const LoaderTitleBox = styled.div`
  display: flex;
  flex-flow: column;
  gap: 12px;
  justify-content: center;
  align-items: flex-start;
`

const LoaderMain = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex-grow: 1;
  width: 100%;
`

const PresetValuesSkeleton = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > span {
    width: 23%;
    height: 34px;
    border-radius: 4px;
  }
`

const Info = styled.div`
  display: flex;
  width: 100%;
  flex-flow: row;
  align-items: flex-start;
  gap: 12px;
`

const Skeleton = styled.span`
  display: block;
  background-color: rgba(0, 0, 0, 0.11);
  border-radius: 4px;
  animation: skeleton-loader 1.5s ease-in-out 0.5s infinite;
`

export function InitialLoader() {
  const hideFooter = window.location.search.indexOf('showFooter=false') > 0

  return (
    <Layout data-testid="initial-loader">
      <LoaderWrapper>
        <LoaderContentWrapper>
          <Skeleton style={{ width: '176px', height: '4px', borderRadius: '4px' }} />
          <LoaderLogoBox>
            <Skeleton
              style={{ width: '52px', height: '52px', transform: 'none', marginRight: '12px', borderRadius: '8px' }}
            />
            <LoaderTitleBox>
              <Skeleton style={{ width: '105px', height: '16px' }} />
              <Skeleton style={{ width: '65px', height: '16px' }} />
            </LoaderTitleBox>
          </LoaderLogoBox>
          <LoaderMain>
            <Skeleton style={{ height: '40px' }} />
            <PresetValuesSkeleton>
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </PresetValuesSkeleton>
            <Skeleton
              style={{
                height: '56px',
                borderRadius: '8px',
                border: '1px solid #E1E2E3',
                backgroundColor: '#fff'
              }}
            />
            <Info>
              <Skeleton style={{ borderRadius: '50%', width: '16px', height: '16px' }} />
              <Skeleton style={{ width: '92%', height: '64px' }} />
            </Info>
          </LoaderMain>
          <Skeleton
            style={{ width: '100%', height: '48px', marginTop: '32px', marginBottom: '36px', borderRadius: '8px' }}
          />
        </LoaderContentWrapper>
        {!hideFooter && (
          <Skeleton data-testid="skeleton-footer" style={{ borderRadius: 'unset', height: '104px', width: '100%' }} />
        )}
      </LoaderWrapper>
    </Layout>
  )
}
